import styled from "styled-components";

export const ContainerContact = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    @media(max-width: 1038px){
        margin-top: 3%;
    }
`
export const Form = styled.form`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-top: 2%;
padding-left: 2%;
padding-right: 2%;
padding-bottom: 5%;
margin-bottom: 1%;
margin-top: 0%;
@media(max-width: 383px){
    margin-top: 0%;
    padding-top: 0%;
}
@media (max-width:414px) and (max-height:736px){
    margin-bottom: 19%;
}
@media (max-width:360px) and (max-height:740px){
    margin-bottom: 18%;
}
@media (max-width:375px) and (max-height: 812px){
    margin-bottom: 23%;
}
@media (max-width:375px) and (max-height:667px){
    margin-bottom: 21%;
}
`
export const InputDiv = styled.div`
`
export const Input = styled.input`
margin: 10px;
width: 300px;
height: 50px;
border-top: none;
border-left: none;
border-right: none;
background-color: transparent;
font-size: 25px;
color: white;
@media (max-width: 674px){
    width: 150px;
    font-size: 17px;
    margin: 30px;
}
@media (max-width: 445px){
    width: 120px;
}
@media (max-width: 383px){
    width: 80%;
    font-size: 15px;
}
&::placeholder{
    color: black;
}
&:hover{
    border-bottom: 2px solid white;
    cursor: pointer;
}
&:focus{
    outline: none;
}
&::placeholder{
    color: white;
}
`
export const Textarea = styled.textarea`
width: 90%;
height: 300px;
border-radius: 10px 10px 10px 10px;
resize: none;
font-size: 25px;
background-color: transparent;
border: 2px solid white;
margin-top: 5%;
color: white;
&::placeholder{
    color: white;
    text-align: center;
}
&:hover{
    border: 2px solid white;
    cursor: pointer;
    transform: scale(1.02);
    &:focus{
        outline: none;
    }
}
`
export const Button = styled.button`
border-top: none;
border-left : none;
border-right: none;
color: white;
padding-top: 5%;
padding-bottom: 1%;
border-bottom: 1px solid white;
background-color : transparent;
font-size: 20px;
&:hover{
    border-bottom: 2px solid white;
    cursor: pointer;
    font-weight: 600;
}
`
export const Footer = styled.footer`
width: 100%;
position: fixed;
bottom: 0%;
z-index: 9999999999;
`
export const Icons = styled.div`
width: 100%;
display: flex;
justify-content: center;
background-color: white;
@media(max-width: 275px){
    width: 105%;
}
`
export const SpanIcon = styled.a`
font-size: 35px;
margin: 5px;
color: black;
&:hover{
    opacity: 0.5;
}
`