import React from 'react'
import { Div, DivBlock, ContainerProjects } from "../../style/Portfolio"
import Caroussel from '../Caroussel';
import { scalables, venti, prisma, artificial } from '../Data';
import { MainTitleSectionDesign } from '../../style/General';
import "../../index.css"
import Navbar from '../nav/Nav';
import FooterContact from '../FooterContact';
import ChangeLanguage from '../Context/ChangeLanguage';

function Design() {
    return (
        <>
            <Navbar />
            <ChangeLanguage />
            <ContainerProjects>
                <Div>
                    <DivBlock>
                        <MainTitleSectionDesign>DESIGN</MainTitleSectionDesign>
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={venti} />
                        </div>
                    </DivBlock>
                    <DivBlock>
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={scalables} />
                        </div>
                    </DivBlock>
                    <DivBlock>
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={prisma} />
                        </div>
                    </DivBlock>
                    <DivBlock>
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={artificial} />
                        </div>
                    </DivBlock>
                </Div>
            </ContainerProjects>
            <FooterContact/>
        </>
    )
}

export default Design