export const MenuItems = [
    {
      titleFr: 'design',
      titleEn: 'design',
      path: '/design',
      cName: 'dropdown-link'
    },
    {
      titleFr: 'espaces publics',
      titleEn: 'public spaces',
      path: '/space',
      cName: 'dropdown-link'
    },
    {
      titleFr: 'résidentiel',
      titleEn: 'résidential', 
      path: '/residential',
      cName: 'dropdown-link'
    },
  ];