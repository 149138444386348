import styled from "styled-components";

export const MainTitleSection = styled.p`
font-size: 45px;
color: white;
width: 100%;
display: flex;
justify-content: center;
padding-top: 11%;
font-weight: 500;
@media(max-width: 625px){
    padding-top: 15%;
    padding-bottom:10%;
}
@media(max-width: 447px){
    padding-top: 26%;
    padding-bottom:0%;
}
@media(max-width: 275px){
    padding-top: 37%;
}
@media(max-width: 179px){
    padding-top: 90%;
}
`
export const MainTitleSectionAbout = styled.section`
font-size: 45px;
margin-top: 12%;
@media(max-width: 1500px){
margin-top: 5%;
}
@media(max-width: 987px){
    margin-top: 10%;
    }
    @media(max-width: 815px){
        margin-top: 15%;
        }
            @media(max-width: 383px){
                margin-top: 35%;
                }
                @media(max-width: 350px){
                    margin-top: 30%;
                    }
`
export const MainTitleSectionDesign = styled.p`
font-family: "beatrice";
font-size: 45px;
color: white;
width: 100%;
display: flex;
justify-content: center;
padding-top: 2%;
padding-bottom: 5%;
font-weight: 500;
@media(max-width: 1284px){
    padding-top: 15%;
}
@media(max-width: 625px){
    padding-top: 23%;
    padding-bottom:10%;
}
@media(max-width: 447px){
    padding-top: 45%;
    padding-bottom:10%;
}
@media(max-width: 390px){
    font-size: 35px;
}
@media(max-width: 303px){
    font-size: 30px;
}
@media(max-width: 260px){
    font-size: 25px;
}
`
export const MainTitleSectionPortfolio = styled.p`
font-size: 45px;
color: white;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 0%;
margin-top: 0%;
margin-left: 2%;
margin-right: 2%;
text-align: center;
font-weight: 400;
@media(max-width: 866px){
    margin-top: 10%;
    margin-bottom: 10%;
}
@media(max-width: 767px){
    margin-top: 5%;
    margin-bottom: 0%;
}
`
export const OtherTitlePortfolio = styled.p`
font-size: 45px;
color: white;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 5%;
margin-top: 15%;
font-weight: 400;
text-align: center;
`
export const SpanLocation = styled.span`
height: 20vh;
margin-top: 200px;
`
export const SpanLocation2 = styled.span`
height: 10vh;
margin-top: 0px;
`
export const Background = styled.div`
background-color: black;
`