import React from 'react'
import "../style/home.css"
import { useState, useEffect } from "react"
import Navbar from './nav/Nav'
import SyncLoader from "react-spinners/SyncLoader";
import ChangeLanguage from './Context/ChangeLanguage'

function Home({ images }) {
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3500)
    }, [])
    const [current, setCurrent] = useState(0)
    const [autoPlay, setAutoPlay] = useState(true);
    let timeOut = null;

    useEffect(() => {
        timeOut = autoPlay && setTimeout(() => {
            slideRight();
        }, 3000)
    })

    const slideRight = () => {
        setCurrent(current === images.length - 1 ? 0 : current + 1)
    }
    const slideLeft = () => {
        setCurrent(current === 0 ? images.length - 1 : current - 1);
    }


    return (
        <div className="App">
            {
                loading ?
                    <div className="loader">
                        <SyncLoader
                            loading={loading}
                            size={30}
                            width={200}
                            color="white"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            speedMultiplier={0.5}
                        /></div>
                    : <>
                        <div className='animation-apparition'>
                            <Navbar />
                            <ChangeLanguage />
                            <div className='carousel-home'>
                                <div className='carousel_wrapper-home'>
                                    {images.map((image, index) => {
                                        return (
                                            <div key={index} className={index == current ? "carousel_card-home carousel_card-active-home" : "carousel_card-home"}>
                                                <img className='card_image-home' src={image.image} alt="" />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    );
}

export default Home