import React from 'react'
import { Div, DivBlock, ContainerProjects } from "../../style/Portfolio"
import Caroussel from '../Caroussel';
import { suresnes, recouvrementPBC, librairie } from '../Data';
import "../../index.css"
import Navbar from '../nav/Nav';
import { MainTitleSectionDesign } from '../../style/General';
import Footer  from '../FooterContact';
import ChangeLanguage from '../Context/ChangeLanguage';
import { useLanguage } from '../Context/LanguageContext.jsx';

function Space() {
    const { language } = useLanguage()
    return (
        <>
            <Navbar />
            <ChangeLanguage />
            <ContainerProjects>
                <Div>
                    <DivBlock>
                        <MainTitleSectionDesign>{language === 'Fr' ? 'ESPACE PUBLICS' : 'PUBLIC SPACE'}</MainTitleSectionDesign>
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={librairie} />
                        </div>
                    </DivBlock>
                    <DivBlock> 
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={recouvrementPBC} />
                        </div>
                    </DivBlock>
                    <DivBlock>
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={suresnes} />
                        </div>
                    </DivBlock>
              
                </Div>
            </ContainerProjects>
            <Footer/>
        </>
    )
}

export default Space