import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Nav from "./components/nav/Nav";
import About from "./components/About";
import Design from "./components/Portfolio/Design";
import Space from "./components/Portfolio/Space"
import Residential from "./components/Portfolio/Residential"
import Contact from "./components/Contact";
import { home } from "./components/Data";
import Home from "./components/Home";
import FooterContact from './components/FooterContact';
import ProgressBar from "./components/ProgressBar";
import SyncLoader from "react-spinners/SyncLoader";
import {useState, useEffect} from "react"
import {Background} from "./style/General"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LanguageProvider } from './components/Context/LanguageContext';

function App() {
/*<div className="App">
       {
        loading ?
        <div className="loader">
        <SyncLoader
        loading={loading}
        size={30}
        color="black"
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.5}
      /></div>
      :*/
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    }, 4500)
  }, [])
  useEffect(() => {
    AOS.init();
}, [])
  return (<>
  <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home images={home}/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/design" element={<Design/>}></Route>
          <Route path="/space" element={<Space/>}></Route>
          <Route path="/residential" element={<Residential/>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
    </>
)}

export default App;
