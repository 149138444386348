import React from 'react'
import { Div, BorderWhite, ContentAbout, Important, ContainerAbout } from "../style/About"
import { SpanLocation, MainTitleSection, MainTitleSectionAbout } from "../style/General"
import Navbar from './nav/Nav'
import "../style/black-background.css"
import SyncLoader from "react-spinners/SyncLoader";
import { useState, useEffect } from "react"
import FooterContact from './FooterContact'
import ChangeLanguage from './Context/ChangeLanguage'
import { useLanguage } from './Context/LanguageContext'

function About() {
    const { language } = useLanguage();
    console.log(language)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])
    const [current, setCurrent] = useState(0)
    const [autoPlay, setAutoPlay] = useState(true);
    let timeOut = null;

    return (
        <>
                    <Navbar />
                    <ChangeLanguage />

            <ContainerAbout>
                <MainTitleSectionAbout>{language === 'Fr' ? 'A propos' : 'About'}</MainTitleSectionAbout>
                <Div>
                    <BorderWhite>
                        {language === 'Fr' ?  (<ContentAbout>
                        Cléo Perrin est issue d'un <Important>double cursus Architecte intérieur et Design</Important>.
                            <br />
                            Actuellement <Important>assistante architecte d'intérieur pour Anglique Omoré</Important>
                            , et ayant complété des <Important>stages</Important> tant d'<Important>observation</Important>, que de <Important>chantier</Important> ou en <Important>agence</Important>, Cléo Perrin maîtrise les logiciels<br /> <Important>Archicad, Autocad, Sketchup, Rhino 6 Photoshop, Illustrator, inDesign, Lumion ou encore Cinema 4D</Important>.
                        </ContentAbout>
                         ): 
                        <ContentAbout>
                        Cléo Perrin holds a <Important>dual degree in Interior Architecture and Design. </Important>.
                          <br />
                          Currently <Important>working as an assistant interior architect for Angélique Omoré</Important>
                          , she has completed various <Important>internships</Important> including both<Important>observational </Important>, and <Important> site experiences, </Important> as well as in <Important>agencies</Important>. Cléo Perrin is proficient in software such as<br /> <Important>Archicad, Autocad, Sketchup, Rhino 6 Photoshop, Illustrator, inDesign, Lumion and Cinema 4D</Important>.
                      </ContentAbout>
                        }
                    </BorderWhite>
                </Div>
            </ContainerAbout>
        </>
    )
}

export default About